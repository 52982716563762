<template>
    <div id="pageTable">      
        <v-container xl fluid>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Complementos de nómina'"
                        :columns="columns"
                        :items="tableData.data"
                        :paginationInfo="tableData.paginatorInfo"
                        @pagination="getPaginationInfo"
                        :showAdvancedFilters="true"
                        :filters="filters"
                        @setFilters="setFilters"
                        :perPage="[10,25,50,100]"
                        :loading="isLoading"
                        @loadModalData="loadModalData"
                    >
                        <template slot="actionButtonsLeft">
                            <v-btn
                                color="white"
                                rounded
                                class="btn-asociar justify-space-between pr-3 elevation-0 mr-3"
                                @click="abrirModalCuentas()"
                            >
                                <v-icon class="mr-2" style="margin-left:-4px;">swap_horiz</v-icon>
                                Asociar cuenta contable
                            </v-btn>
                            <div class="botonAgregar">
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                            
                        </template>
                        <template slot="filters">
                            <template v-if="rol == 'root'">
                                <v-col xs="12" sm="12" md="12" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="itemsClientes"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Cliente"
                                    persistent-hint
                                    v-model="cliente_value"
                                ></v-autocomplete>
                                </v-col>
                            </template>
                            
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        label="Tipo Catálogo SAT"
                                        :items="tipoCatalogoSAT"
                                        item-text="nombre"
                                        item-value="valor"
                                        persistent-hint
                                        v-model="tipoSAT"
                                        class="pa-0 ma-0"
                                    >
                                    </v-autocomplete>
                            </v-col> 
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0 my-0">
                                <v-autocomplete
                                    outlined
                                    label="Concepto de nómina"
                                    :items="itemsConceptos"
                                    item-text="nombre"
                                    item-value="id"
                                    persistent-hint
                                    v-model="concepto_nomina_value"
                                    class="pa-0 ma-0"
                                />
                            </v-col>
                            <v-col xs="12" sm="3" md="3" class="py-0">
                                <v-text-field
                                    outlined
                                    label="Código"
                                    v-model="codigo"
                                ></v-text-field>
                            </v-col>
                            <v-col xs="12" sm="9" md="9" class="py-0">
                                <v-text-field
                                    outlined
                                    label="Nombre"
                                    v-model="nombre"
                                ></v-text-field>
                            </v-col>
                            
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td><div>{{item.codigo}}</div></td>
                                <td><div>{{item.nombre}}</div></td>
                                <td>
                                    <div class="tblOpciones">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" class="botonHover" icon fab dark small @click="abrirModal('update', item)">
                                                <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                            </v-btn>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(item)">
                                                <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                            </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <template class="borde-card">
                <v-dialog v-model="dialog" persistent max-width="700px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img :src="imagen" class="imgModal" />
                                    <h2 class="titleModal">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <div id="padre" v-show="isLoadingModal">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoadingModal" grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        <v-col v-if="rol == 'root'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Clientes" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Clientes"
                                                    :items="itemsClientes"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="cliente_value"
                                                    class="pa-0 ma-0"
                                                    required 
                                                    :error-messages="errors" 
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Tipo Catálogo SAT" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Tipo Catálogo SAT"
                                                    :items="tipoCatalogoSAT"
                                                    item-text="nombre"
                                                    item-value="valor"
                                                    persistent-hint
                                                    v-model="tipoSAT"
                                                    class="pa-0 ma-0"
                                                    required 
                                                    :error-messages="errors" 
                                                    :disabled="cliente_value == null"
                                                    :readonly="accion == 'add'? false:true "
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col> 
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Concepto de nómina" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Concepto de nómina"
                                                    :items="itemsConceptos"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="incidencia.concepto_nomina_id"
                                                    class="pa-0 ma-0"
                                                    required 
                                                    :error-messages="errors" 
                                                    :readonly="accion == 'add'? false:true "
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                         <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="Código" rules="required">
                                                <v-text-field 
                                                    outlined
                                                    disabled
                                                    label="Código" 
                                                    class="pa-0 ma-0" 
                                                    v-model="incidencia.codigo" 
                                                    :error-messages="errors" 
                                                    required
                                                >
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                                                <v-text-field 
                                                    outlined
                                                    label="Nombre" 
                                                    class="pa-0 ma-0" 
                                                    v-model="incidencia.nombre" 
                                                    :error-messages="errors" 
                                                    required
                                                >
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                            <v-text-field 
                                                outlined
                                                label="Clave SAT" 
                                                class="pa-0 ma-0" 
                                                v-model="claveSAT" 
                                                disabled
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="Descripción" rules="required">
                                                <v-text-field 
                                                    outlined
                                                    label="Descripción" 
                                                    class="pa-0 ma-0" 
                                                    v-model="incidencia.descripcion" 
                                                    :error-messages="errors" 
                                                    required
                                                >
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col v-if="showComplementoIMSS" cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <div class="subtitles-options mb-3">Tipo de percepción IMSS</div>
                                        </v-col>

                                        <v-col v-if="showComplementoIMSS" cols="12" xs="12" sm="12" md="12" class="py-0 mb-7">
                                            <v-radio-group class="ma-0 pa-0" v-model="incidencia.tipo_complemento_imss" row>
                                                <v-radio label="Fijo" value="fijo"></v-radio>
                                                <v-radio label="Variable" value="variable"></v-radio>
                                            </v-radio-group>
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <div class="subtitles-options mb-3">Otras acciones</div>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0 d-flex">
                                            <v-switch class="pa-0 ma-0 " v-model="incidencia.multiple" label="Múltiple"></v-switch>
                                            <v-tooltip top class="pb-7">
                                                <template v-slot:activator="{ on }">
                                                    <v-icon color='#96999A' v-on="on" slot="activator" class="pb-7 pl-2 outlined_v_icon">info</v-icon>
                                                </template>
                                                <span class="pb-7 textTooltip">
                                                    Determina si el concepto de nómina <br>
                                                    puede aparecer más de una vez en el <br>
                                                    período de complementos.
                                                </span>
                                            </v-tooltip>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" v-if="diasPeriodo">
                                            <v-checkbox class="pa-0 ma-0" v-model="incidencia.incluir_vacaciones" label="Adicionar días al periodo"></v-checkbox>
                                        </v-col>

                                        <template v-for="(item, index) in camposAdicionales">
                                            <v-col :key="index" cols="12" xs="12" :sm="(item.tipo=='BOOL')?12:6" :md="(item.tipo=='BOOL')?12:6" class="py-0 ma-0 d-flex">
                                                <template v-if="item.tipo == 'STRING'">
                                                    <v-text-field
                                                        outlined
                                                        :label="item.nombre"
                                                        class="pa-0 ma-0" 
                                                        v-model="valor_condicional"
                                                    />
                                                </template>

                                                <template v-if="item.tipo == 'INTEGER'">
                                                    <v-text-field
                                                        v-model="valor_condicional"
                                                        outlined
                                                        :label="item.nombre"
                                                        v-mask="'#######'"
                                                        class="pa-0 ma-0"
                                                    />
                                                </template>

                                                <template v-if="item.tipo == 'FLOAT' && item.requerido == false">
                                                    <v-text-field
                                                        outlined
                                                        :label="item.nombre"
                                                        class="pa-0 ma-0"
                                                        v-on:keypress="presicionNumero($event,valor_condicional)"
                                                        oninput="if(this.value<0) this.value = 0; this.value=this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                                        v-model="valor_condicional" 
                                                    />
                                                </template>

                                                <template v-if="item.tipo == 'FLOAT' && item.requerido == true">
                                                    <ValidationProvider v-slot="{ errors }" :name="item.nombre" rules="required">
                                                        <v-text-field
                                                            outlined
                                                            :label="item.nombre"
                                                            class="pa-0 ma-0"
                                                            v-on:keypress="presicionNumero($event,valor_condicional)"
                                                            oninput="if(this.value<0) this.value = 0; this.value=this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                                            v-model="valor_condicional" 
                                                            :error-messages="errors" 
                                                            required
                                                        />
                                                    </ValidationProvider>
                                                </template>



                                                <template v-if="item.tipo == 'BOOL'">
                                                    <v-checkbox
                                                        class="pa-0 ma-0 checkboxVuetify" 
                                                        v-model="valor_condicional" 
                                                        :label="item.nombre"
                                                    >
                                                    </v-checkbox>
                                                </template>
                                                <v-tooltip top class="pb-7" v-if="item.informativo"> 
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon color='#96999A' v-on="on" slot="activator" class="pb-7 pl-2 outlined_v_icon">info</v-icon>
                                                    </template>
                                                    <span class="pb-7 textTooltip">
                                                        {{item.descripcion}}
                                                    </span>
                                                </v-tooltip>
                                            </v-col>
                                        </template>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()" 
                                    :disabled="isSaving"
                                >
                                    Cancelar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="guardar()" 
                                    :disabled="invalid" 
                                    :loading="isSaving"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>

            <template class="borde-card">
                <v-dialog v-model="dialogCuentas" persistent max-width="950px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalCuentas"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <v-card-title class="padding_izquierdo padding_derecho padding_arriba">
                                <v-btn
                                    @click="cerrarModalCuentas()"
                                    small
                                    slot="activator"
                                    icon
                                    text
                                    class="v-btn-cerrar modal-pull-right"
                                    absolute
                                    top
                                    right
                                >
                                    <v-icon class="icono-cerrar"></v-icon>
                                </v-btn>
                            <div class="headerModalStart">
                                <h2 class="titleModalCuentas">Asociar cuenta contable</h2>
                            </div>
                        </v-card-title>

                        <v-card-text class="padding_izquierdo padding_derecho padding_abajo">


                            <v-overlay :z-index="999" :value="isLoadingCuentas" :absolute="true">
                                <div class="align-center justify-center text-center">
                                    <v-progress-circular
                                        indeterminate
                                        :size="100"
                                        :width="7"
                                        color="#2DB9D1"
                                    >
                                        <v-img
                                            src="/static/icon/favicon.ico"
                                            :width="60"
                                        ></v-img>
                                    </v-progress-circular>
                                    <p class="mt-5">Cargando...</p>
                                </div>
                            </v-overlay>
                            <!-- <div id="padre" v-show="isLoadingCuentas">
                                <div id="loading">
                                    <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                </div>
                            </div> -->
                            
                            <v-container grid-list-md id="contenedor">
                                <v-row class="pa-0 ma-0">
                                    <ValidationObserver ref="form" v-slot="{ invalid }">
                                        <v-row class="my-0 mt-8">
                                            <v-col v-if="rol == 'root'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Clientes" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Clientes"
                                                        :items="itemsClientes"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="cliente_value"
                                                        class="pa-0 ma-0"
                                                        required 
                                                        :error-messages="errors" 
                                                        :disabled="accionCuenta == 'update'"
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="4" md="4" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Registro patronal" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Registro patronal"
                                                        :items="itemsRegistrosPatronales"
                                                        item-text="nombreClave"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="registro_patronal_id"
                                                        class="pa-0 ma-0"
                                                        required 
                                                        :error-messages="errors" 
                                                        :disabled="accionCuenta == 'update'"
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="4" md="4" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Complementos" :rules="es_bancaria ? '':'required'">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Complementos"
                                                        :items="itemsComplementos"
                                                        item-text="descripcion_larga"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="tipo_incidencia_nomina_id"
                                                        class="pa-0 ma-0"
                                                        required 
                                                        :error-messages="errors" 
                                                        :disabled="es_bancaria"
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="4" md="4" class="pt-0 pb-0 ma-0">
                                                <ValidationProvider v-slot="{ errors }" name="Cuenta contable" rules="required">
                                                    <v-text-field 
                                                        outlined
                                                        label="Cuenta contable" 
                                                        class="pa-0 ma-0" 
                                                        v-model="cuenta_contable" 
                                                        :error-messages="errors" 
                                                        required
                                                    >
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0 d-flex">
                                                <v-checkbox class="pa-0 ma-0 checkboxVuetify" v-model="es_bancaria" label="¿Agregar cuenta de banco?"></v-checkbox>
                                                <v-tooltip top class="pb-7">
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon color='#96999A' v-on="on" slot="activator" class="pb-7 pl-2 outlined_v_icon">info</v-icon>
                                                    </template>
                                                    <span class="pb-7 textTooltip">
                                                        La cuenta contable de Bancos se aplicará al neto a <br>
                                                        pagar para efectos de cuadre del asiento contable, <br>
                                                        solamente puede existir una por Registro Patronal.
                                                    </span>
                                                </v-tooltip>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12" class="d-flex justify-end py-0">
                                                 <button
                                                    class="btnCerrar"
                                                    @click="cancelarActualizacion()" 
                                                    :disabled="isSaving"
                                                    v-if="accionCuenta == 'update'"
                                                >
                                                    Cancelar
                                                </button>
                                                <v-btn
                                                    class="btnGuardar"
                                                    @click="guardarCuenta()" 
                                                    :disabled="invalid" 
                                                    :loading="isSaving"
                                                >
                                                    {{accionCuenta=='add' ? 'Guardar':'Actualizar'}}
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12" class="pt-5 pb-4">
                                                <div class="trDivider"><div class="mb-2"></div></div>
                                            </v-col>
                                            
                                        </v-row>
                                    </ValidationObserver>
                                    
                                    
                                    <v-col cols="12" xs="12" sm="12" md="12" class="pa-0 ma-0">
                                        <div style="width:100%;overflow-x:auto;" id="tablaCuenta">

                                        
                                        <table class="tbl-modal" >
                                            <thead style="border-top-left-radius: 20px !important; border-top-right-radius: 20px !important;" class="tbl-header">
                                                <tr style="border-top-left-radius: 20px; border-top-right-radius: 20px;" class="modaltr">
                                                    <th style="border-top-left-radius: 20px;" class="pl-7 modalth">Registro patronal</th>
                                                    <th class="modalth">Complemento</th>
                                                    <th class="modalth">Cuenta contable</th>
                                                    <th style="border-top-right-radius: 20px;" class="modalth justify-center text-center">Opciones</th>
                                                </tr>
                                            </thead>
                                            <tbody id="tablaCuentaBody">
                                                <tr v-if="cuentasComplementos.length == 0" class="modaltr">
                                                    <td class="emptyTableModal" colspan="4">
                                                        No hay elementos para mostrar
                                                    </td>
                                                </tr>
                                                <template v-else v-for="(complemento, index) in cuentasComplementos">
                                                    <template>
                                                        <tr :key="index" class="modaltr">
                                                            <td class="pl-7 modaltd">
                                                                <div>{{complemento.registro_patronal.nombre}}</div>
                                                            </td>
                                                            <td class="modaltd"> 
                                                                <div v-if="complemento.es_bancaria">
                                                                    Bancos
                                                                </div>
                                                                <div v-else>{{complemento.tipo_incidencia_nomina.codigo}} - {{complemento.tipo_incidencia_nomina.nombre}}</div>
                                                            </td>
                                                            <td class="modaltd"> 
                                                                <div>{{complemento.cuenta_contable}}</div> 
                                                            </td>
                                                            <td class="justify-center text-center">
                                                                <div class="mt-2">
                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-btn v-on="on" class="botonHover" icon fab dark small @click="actualizarCuenta(complemento)">
                                                                                <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                                                            </v-btn>
                                                                        </template>
                                                                        <span>Editar</span>
                                                                    </v-tooltip>
                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminarCuenta(complemento)">
                                                                                <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                                            </v-btn>
                                                                        </template>
                                                                        <span>Eliminar</span>
                                                                    </v-tooltip>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr :key="'A'+index" class="trDivider modaltr"><div class="my-2"></div></tr>
                                                    </template>
                                                </template>
                                            </tbody>
                                        </table>

                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </template>
        </v-container>
    </div>
</template>

<script>
import Datatable from '@/components/g-datatable/Datatable.vue';
import queries from '@/queries/TipoIncidenciasNom';
import Notify from '@/plugins/notify';
import apiTipoIncidencias from '@/api/tipoIncidenciaNom';
import apiClientes from '@/api/clientes';
import queriesConceptoNomina from '@/queries/ConceptoNomina';
import apiRegistroPatronal from "@/api/nominas/registroPatronal";
import PerfectScrollbarVue from 'perfect-scrollbar';

export default {
    components: {
        'data-table': Datatable,
    },
    data() {
        return {
            diasPeriodo:false,
            columns         : [
                {
                    label: 'Código',
                    name: 'codigo',
                    filterable: true
                },
                {
                    label: 'Nombre',
                    name: 'nombre',
                    filterable: true
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            filters         : {AND:[]},
            tableData       : {},
            isLoading       : true,
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            incidencia           : {
                id                      : null,
                cliente_id              : null,
                concepto_nomina_id      : null,
                codigo                  : null,
                nombre                  : null,
                descripcion             : null,
                incluir_vacaciones      : false,
                multiple                : false,
                tipo_complemento_imss   : 'fijo',
                valor_condicional       : null,
            },
            datosLogin      : null,
            rol             : null,
            accion          : null,
            tituloModal     : null,
            dialog          : false,
            isSaving        : false,
            imagen          : '',
            itemsClientes   : [],
            cliente_value   : null,
            concepto_nomina_value : null,
            nombre          : null,
            codigo          : null,
            itemsConceptos  : [],
            tipoCatalogoSAT : [
                {id:1, nombre:"Percepciones",valor:"percepciones"}, 
                {id:2, nombre:"Deducciones", valor:"deducciones"}, 
                {id:3, nombre:"Otros pagos", valor:"otros_pagos"},
                /*{id:4, nombre:"Informativo", valor:"informativos"} */
            ],
            tipoSAT                     : null,
            tipoSATupdate               : null,
            isLoadingModal              : false,
            invalid                     : false,
            camposAdicionales           : [],
            valor_condicional           : null,
            concepto_nomina_id          : null,
            dialogCuentas               : false,
            isLoadingCuentas            : false,
            cuentasComplementos         : [],
            itemsRegistrosPatronales    : [],
            registro_patronal_id        : null,
            itemsComplementos           : [],
            tipo_incidencia_nomina_id   : null,
            cuenta_contable             : null,
            id_cuenta                   : null,
            accionCuenta                : '',
            es_bancaria                 : false,
            showComplementoIMSS         : false,
        }
    },
    computed:{
        claveSAT: function() {
            let clave = '';

            let concepto =  this.itemsConceptos.find(item => item.id == this.incidencia.concepto_nomina_id )
            
            if(concepto!=null){
                if(concepto.SAT.length != 0 ){
                    clave = concepto.SAT[1] + ' - ' + concepto.SAT[2] 
                }
                else{
                    clave = '';
                }
            }

            return clave
        },
    },
    watch: {
        es_bancaria:function(val){
            if(val){
                this.tipo_incidencia_nomina_id = null;
            }
        },
        tipoSAT:function(val) {
            if(val!=null){
                this.getConceptoNomina(val);
                if((this.accion =='add') || (this.accion == 'update' && this.tipoSATupdate != this.tipoSAT)){
                    this.getCodigo(val)
                }
                if(this.accion == 'update'){
                    if(this.tipoSATupdate == this.tipoSAT){
                        this.incidencia.codigo = this.codigo_update;
                    }
                }
            }
        },
        "incidencia.concepto_nomina_id":function(val, oldVal){
            if(val != null){
                for(let item of this.itemsConceptos){
                    if(item.id == val){
                        if(item.campos_adicionales != null){
                            this.camposAdicionales = JSON.parse(item.campos_adicionales);
                        }
                        else{
                            this.camposAdicionales = [];
                            this.valor_condicional = null;
                        }

                        if(item.funcion_nombre == "p_Vacaciones"){
                            this.diasPeriodo = true;
                        }else{
                            this.diasPeriodo = false;
                        }

                        if(this.accion =='add' && item.funcion_nombre == 'p_HonorariosAsimilados'){
                            this.valor_condicional = false;
                        }
                        else if(this.accion =='add' && item.funcion_nombre != 'p_HonorariosAsimilados'){
                            this.valor_condicional = null;
                        }

                        if(oldVal != null && this.accion =='update' && item.funcion_nombre == 'p_HonorariosAsimilados'){
                            this.valor_condicional = false;
                        }
                        else if (oldVal != null && this.accion =='update' && item.funcion_nombre != 'p_HonorariosAsimilados'){
                            this.valor_condicional = null;
                        }


                        if(this.accion =='add'){
                            switch (item.codigo.charAt(0)) {
                                case 'P':
                                    if(item.funcion_nombre != 'p_HonorariosAsimilados'){
                                        this.showComplementoIMSS = true;
                                        this.incidencia.tipo_complemento_imss = 'fijo';

                                        if(
                                            item.funcion_nombre == 'p_PTU' ||
                                            item.funcion_nombre == 'p_Comisiones' ||
                                            item.funcion_nombre == 'p_FondoAhorro' ||
                                            item.funcion_nombre == 'p_CajaAhorro' ||
                                            item.funcion_nombre == 'p_PremioPuntualidad' ||
                                            item.funcion_nombre == 'p_PremioAsistencia' ||
                                            item.funcion_nombre == 'p_Gratificaciones' ||
                                            item.funcion_nombre == 'p_HorasExtrasDobles' ||
                                            item.funcion_nombre == 'p_HorasExtrasTriples' ||
                                            item.funcion_nombre == 'p_PrimaDominical' ||
                                            item.funcion_nombre == 'p_PrimaAntiguedad' ||
                                            item.funcion_nombre == 'p_Indemnizacion' ||
                                            item.funcion_nombre == 'p_GratificacionSeparacion' ||
                                            item.funcion_nombre == 'p_OtrosIngresos' ||
                                            item.funcion_nombre == 'p_FondoSupervivencia' ||
                                            item.funcion_nombre == 'p_Incentivos' ||
                                            item.funcion_nombre == 'p_DescansoLaborado' ||
                                            item.funcion_nombre == 'p_SubsidioIncapacidad' ||
                                            item.funcion_nombre == 'p_diaFestivo' ||
                                            item.funcion_nombre == 'p_ValesDespensa'
                                        ){
                                            this.incidencia.tipo_complemento_imss = 'variable';
                                        }
                                        else{
                                            //p_Sueldo
                                            //p_Aguinaldo
                                            //p_Vacaciones
                                            //p_PrimaVacacional
                                            //p_VacacionesFiniquito
                                            //p_PrimaVacacionalFiniquito

                                            this.incidencia.tipo_complemento_imss = 'fijo';
                                        }
                                    } 
                                    else{
                                        this.showComplementoIMSS = false;
                                        this.incidencia.tipo_complemento_imss = null; // null
                                    }
                                    break;
                                case 'D':
                                    this.showComplementoIMSS = false;
                                    this.incidencia.tipo_complemento_imss = null; // null

                                    break;
                                case 'O':
                                    this.showComplementoIMSS = false;
                                    this.incidencia.tipo_complemento_imss = null; // null
                                    break;
                                case 'I':
                                    this.showComplementoIMSS = false;
                                    this.incidencia.tipo_complemento_imss = null; // null
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                }
            }
        },
        cliente_value: function(val){
            if(val != null){
                this.getRegistroPatronal();
                this.getTiposIncidencias();
            }
        },
        registro_patronal_id: function(val){
            if(val != null){
                this.getCuentasContables();
            }
        },
    },
    methods: {
        listar(){
            this.datosLogin = this.$session.get('usuario');
            this.rol = this.datosLogin.rol.name;

            if(this.rol == "root"){
                this.getClientes();
            }else{
                this.cliente_value = this.datosLogin.cliente_id;
                this.filters.AND.push({column:'CLIENTE_ID', value:this.datosLogin.cliente_id});
            }
        },
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }
            this.isLoading = true;
        },
        async abrirModal(accion, data = null){
            this.accion     = accion;
            this.isLoadingModal = true;
            this.imagen     = "/static/icon/catalogos.png"
            if(this.rol == 'root'){
                this.getClientes();
            }

            if(accion == "add"){
                this.tituloModal    = "Registrar complemento de nómina";
                this.imagen         = "/static/modal/tipoIncidenciaNominaCreate.svg";
                if(this.rol != 'root'){
                    this.cliente_value = this.datosLogin.cliente_id;
                }
                
            } else {
                this.tituloModal    = "Actualizar complemento de nómina";
                this.imagen         = "/static/modal/tipoIncidenciaNominaUpdate.svg";

                switch (data.codigo.charAt(0)) {
                    case 'P':
                        this.tipoSAT = 'percepciones';
                        this.tipoSATupdate = 'percepciones';
                        break;
                    case 'D':
                        this.tipoSAT = 'deducciones';
                        this.tipoSATupdate = 'deducciones';
                        break;
                    case 'O':
                        this.tipoSAT = 'otros_pagos';
                        this.tipoSATupdate = 'otros_pagos';
                        break;
                    case 'I':
                        this.tipoSAT = 'informativos';
                        this.tipoSATupdate = 'informativos'
                        break;
                    default:
                        break;
                }

                await this.getConceptoNomina(this.tipoSAT);
                this.incidencia.id                  = data.id;
                this.incidencia.cliente_id          = data.cliente_id;
                this.cliente_value                  = data.cliente_id;
                this.concepto_nomina_id             = data.concepto_nomina_id;


                this.incidencia.codigo              = data.codigo;
                this.incidencia.nombre              = data.nombre;
                this.incidencia.descripcion         = data.descripcion;
                this.incidencia.incluir_vacaciones  = data.incluir_vacaciones;
                this.incidencia.codigo              = data.codigo;
                this.codigo_update                  = data.codigo;
                this.incidencia.multiple            = data.multiple;
                this.incidencia.tipo_complemento_imss = data.tipo_complemento_imss;

                if(data.tipo_complemento_imss == 'fijo' || data.tipo_complemento_imss == 'variable'){
                    this.showComplementoIMSS = true;
                }
                else{
                    this.showComplementoIMSS = false;
                }
                

                if(data.conceptoNomina.funcion_nombre == 'p_HonorariosAsimilados'){
                    if(data.valor_condicional == 'true'){
                        this.valor_condicional = true;
                    }
                    else {
                        this.valor_condicional = false;
                    }
                }
                else{
                    this.valor_condicional     = data.valor_condicional;
                }
                this.diasPeriodo = (data.conceptoNomina.funcion_nombre == "p_Vacaciones" || data.conceptoNomina.funcion_nombre == "d_Ausencia" ) ? true:false;

            }
            this.isLoadingModal  = false;
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },
        cerrarModal() {
            this.$apollo.queries.conceptosNominas.skip = true;
            this.dialog     = false;
            this.isSaving   = false;
            this.resetValues();
        },
        resetValues() {
            this.incidencia      = {
                id                      : null,
                cliente_id              : null,
                concepto_nomina_id      : null,
                codigo                  : null,
                nombre                  : null,
                descripcion             : null,
                incluir_vacaciones      : false,
                multiple                : false,
                tipo_complemento_imss   : 'fijo',
                valor_condicional       : null,
            }
            this.tipoSAT = null;
            this.itemsConceptos =  [];
            this.diasPeriodo = false;

            this.camposAdicionales   = [];
            this.valor_condicional   = null;
            this.concepto_nomina_id  = null;
            
            this.$nextTick(() => {
                if(this.$refs.form != null){
                    this.$refs.form.reset();
                }

                //this.tipoSAT = null
                if (this.rol == "root") {    
                    this.cliente_value  = null;
                }
                this.concepto_nomina_value = null;
                this.nombre = null;
                this.codigo = null;

            });
        },
        async loadModalData() {
            await this.resetValues(); 
            if (this.rol != "root") {    
                this.cliente_value = this.datosLogin.cliente_id;
            } 
        },
        setFilters() {
            this.filters = {AND:[]};
            
            let cliente = this.cliente_value;
            let nombre = this.nombre;
            let codigo = this.codigo;
            let concepto_nomina = this.concepto_nomina_value;

            if (this.rol != "root") {    
                cliente = this.datosLogin.cliente_id;
            } 

            this.isLoading = true;

            if(cliente != null && cliente != undefined && cliente != ""){
                this.filters.AND.push({column:'CLIENTE_ID', value:cliente});
            }

            if(codigo != null && codigo != undefined && codigo != ""){
                this.filters.AND.push({column:'CODIGO', operator:"ILIKE", value:"%"+codigo+"%"});
            }

            if(concepto_nomina != null && concepto_nomina != undefined && concepto_nomina != ""){
                this.filters.AND.push({column:'CONCEPTO_NOMINA_ID', value:concepto_nomina});
            }

            if(nombre != null && nombre != undefined && nombre != ""){
                this.filters.AND.push({column:'NOMBRE',operator:'ILIKE', value:"%"+nombre+"%"});
            }

            this.paginationData.numberPage = 1;
            this.$apollo.queries.tipos_incidencia_nomina.refetch();
            this.resetValues();
        },
        eliminar(data){
            Notify.Alert(
                "¿Estás seguro de eliminar este complemento de nómina?",
                "Esta acción <b>puede afectar</b> algunas funcionalidades del sistema.",
                "Eliminar",
                () => {
                    this.isLoading = true;
                    apiTipoIncidencias.delete(data.id).then(response => {
                        Notify.Success("Complemento de nómina eliminado", "El complementos de nómina ha sido eliminada satisfactoriamente");
                        this.$apollo.queries.tipos_incidencia_nomina.refetch();
                    })
                    .catch(err => {
                        this.isLoading = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                },
                null,
                true,
                true
            )
        },
       
        guardar() {
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }
                this.incidencia.cliente_id = this.cliente_value;

                this.incidencia.valor_condicional = this.valor_condicional;

                if(typeof this.valor_condicional === 'boolean'){
                    if(this.valor_condicional){
                        this.incidencia.valor_condicional = 'true'
                    }
                    else{
                         this.incidencia.valor_condicional = 'false'
                    }
                }


                if(this.accion === "add") {
                    apiTipoIncidencias.store(this.incidencia)
                    .then((response) => {
                        this.isLoading = true;
                        Notify.Success("Operación exitosa", "El complemento de nómina se guardó satisfactoriamente.");
                        this.$apollo.queries.tipos_incidencia_nomina.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
                else if(this.accion === "update") {
                    apiTipoIncidencias.update(this.incidencia)
                    .then((response) => {
                        this.isLoading = true;
                        Notify.Success("Operación exitosa", "El complemento de nómina se actualizó satisfactoriamente.");
                        this.$apollo.queries.tipos_incidencia_nomina.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            });
        },
        getClientes(){
            let parametros = { activo:true, paginate:false }
            apiClientes.getClientes(parametros).then((response) => {
                this.itemsClientes = response.data;
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los Clientes.");
            });
        },
        getConceptoNomina(valor){
            this.$apollo.queries.conceptosNominas.skip = false;
        },
        
        getCodigo(valor){
            let parametros_codigo = { cliente_id: this.cliente_value, tipo: valor};
            apiTipoIncidencias.getCodigo(parametros_codigo).then((response)=>{
                this.incidencia.codigo = response.data;
            })
            .catch(err => {
                
                let error = Object.values(err.response.data.error);
                let leyenda = ``;
                for (var i = 0; i < error.length; i++) {
                    leyenda+= `* ` + error[i] + `\n`;
                }
                Notify.ErrorToast(leyenda);
                this.isSaving = false;
            });
        },
        presicionNumero(event, valor){
            if (/\.\d{3}/.test(valor)) 
                return event.preventDefault();
        },
        async abrirModalCuentas(){
            this.isLoadingCuentas = true;
            this.accionCuenta = 'add';
            if(this.rol != 'root'){
                this.cliente_value = this.datosLogin.cliente_id;
                await this.getRegistroPatronal();
                await this.getTiposIncidencias();
            }

            this.dialogCuentas = true;
            setTimeout(() => { 
                this.isLoadingCuentas = false;
            }, 1000);
        },
        cerrarModalCuentas(){
            this.dialogCuentas = false;
            if(this.rol == 'root'){
                this.cliente_value = null;
                this.itemsRegistrosPatronales   = [];
                this.itemsComplementos          = [];
            }
            this.registro_patronal_id       = null;
            this.tipo_incidencia_nomina_id  = null;
            this.cuenta_contable            = null;
            this.id_cuenta                  = null;
            this.cuentasComplementos        = [];
            this.cerrarModal();
        },
        getCuentasContables(){
            let param = {
                registro_patronal_id: this.registro_patronal_id,
                activo: true
            }
            apiTipoIncidencias.getCuentasContables(param).then((response) => {
                this.cuentasComplementos = response.data;
            })
            .catch(err => {
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }
                    Notify.ErrorToast(leyenda);
                }
            });
        },
        guardarCuenta(){
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }
                
                this.isLoadingCuentas = true;
                if(this.accionCuenta === "add") {
                    let param = {
                        cliente_id: this.cliente_value,
                        registro_patronal_id : this.registro_patronal_id,
                        tipo_incidencia_nomina_id: this.tipo_incidencia_nomina_id,
                        cuenta_contable: this.cuenta_contable,
                        es_bancaria: this.es_bancaria,
                        activo: true
                    }
                    apiTipoIncidencias.storeCuenta(param).then((response) => {
                        Notify.Success("Operación exitosa", "El registro se guardó satisfactoriamente.");
                        this.tipo_incidencia_nomina_id  = null;
                        this.cuenta_contable            = null;
                        this.id_cuenta                  = null;
                        this.es_bancaria                = false;
                        this.isLoadingCuentas           = false;
                        this.isSaving = false;
                        this.getCuentasContables();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isSaving = false;
                        this.isLoadingCuentas = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
                else if(this.accionCuenta === "update") {
                    let param = {
                        id: this.id_cuenta,
                        cliente_id: this.cliente_value,
                        registro_patronal_id : this.registro_patronal_id,
                        tipo_incidencia_nomina_id: this.tipo_incidencia_nomina_id,
                        cuenta_contable: this.cuenta_contable,
                        es_bancaria: this.es_bancaria,
                        activo: true
                    }
                    apiTipoIncidencias.updateCuenta(param).then((response) => {
                        Notify.Success("Operación exitosa", "El registro se guardó satisfactoriamente.");
                        this.tipo_incidencia_nomina_id  = null;
                        this.cuenta_contable            = null;
                        this.id_cuenta                  = null;
                        this.isLoadingCuentas           = false;
                        this.es_bancaria                = false;
                        this.accionCuenta               = "add"
                        this.isSaving = false;
                        this.getCuentasContables();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isSaving = false;
                        this.isLoadingCuentas = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            });
        },
        actualizarCuenta(item){
            this.accionCuenta = "update";
            this.es_bancaria = item.es_bancaria;
            this.tipo_incidencia_nomina_id= item.tipo_incidencia_nomina_id;
            this.cuenta_contable= item.cuenta_contable;
            this.id_cuenta = item.id;
        },
        cancelarActualizacion(){
            this.tipo_incidencia_nomina_id= null;
            this.cuenta_contable= null;
            this.id_cuenta = null;
            this.es_bancaria = false;
            this.accionCuenta = "add"
        },
        eliminarCuenta(item){
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro de eliminar este registro?",
                "Eliminar",
                () => {
                    this.isLoadingCuentas = true;
                    apiTipoIncidencias.deleteCuenta(item.id).then(response => {
                        Notify.Success("Registro eliminado", "El registro ha sido eliminado satisfactoriamente");
                        this.isLoadingCuentas = false;
                        this.getCuentasContables();
                    })
                    .catch(err => {
                        this.isLoadingCuentas = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                },
                null,
                true,
                true
            )
        },
        async getRegistroPatronal(){
            this.itemsRegistrosPatronales = [];
            let parametros = { activo:true, paginate:false, order:"asc", cliente_id: this.cliente_value }
            if(this.cliente_value == null){
                return;
            }
            await apiRegistroPatronal.find(parametros).then((response) => {
                let format = response.data;
                if(response.data.length != 0){
                    format.forEach( item => {
                        item.nombreClave = item.nombre + ' - ' + item.clave
                        this.itemsRegistrosPatronales.push(item);
                    })
                }
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los Registros Patronales.");
            });
        },
        async getTiposIncidencias() {
            let params = {
                cliente_id : this.cliente_value
            };

            await apiTipoIncidencias.getTiposIncidencias(params).then(response => {
                let complementos = response.data;
                complementos.forEach(element => {
                    element["descripcion_larga"] = element["codigo"] + " - " + element["nombre"];
                });
                this.itemsComplementos = complementos;
            })
            .catch(error => console.error(error));
        },
        tableScrollX(){
            const elementScrollX = document.querySelector('#tablaCuenta');
            if(elementScrollX) {
                const ps = new PerfectScrollbarVue(elementScrollX, {
                    wheelSpeed: 2,
                    wheelPropagation: true,
                    minScrollbarLength: 20
                });
            }
        },
        tableScrollY(){
            const elementScroll = document.querySelector(`#tablaCuentaBody`);
            if(elementScroll) {
                const ps = new PerfectScrollbarVue(elementScroll, {
                    wheelSpeed: 2,
                    wheelPropagation: true,
                    minScrollbarLength: 20
                });3
            }
        },
        

    },
    created(){
        this.listar();
    },
    mounted(){
        this.$apollo.queries.tipos_incidencia_nomina.skip = false;
    },
    updated() {
        this.tableScrollX()
        this.tableScrollY();
    },
    apollo: {
        tipos_incidencia_nomina: {
            query       : queries.tipoIncidenciaTableQuery,
            fetchPolicy : 'network-only',
            variables() {
                this.isLoading =  true;
                return {
                    whereConditions : this.filters,
                    numberItems : this.paginationData.numberItems,
                    numberPage  : this.paginationData.numberPage,
                    fieldOrder  : this.$refs.tabla.sortKey ? this.$refs.tabla.sortKey : 'nombre',
                    Order       : this.$refs.tabla.Order,
                }
            },
            skip() {
                return true
            },
            result({loading, error}) {
                this.isLoading  = false;
                this.loading    = false;
                this.tableData  = this.tipos_incidencia_nomina;
            },
        },
        conceptosNominas: {
            query       : queriesConceptoNomina.conceptosNominas,
            fetchPolicy : 'network-only',
            variables() {
                return {
                    whereConditions:{AND:[{column:"TIPO",value:this.tipoSAT}]},
                    numberItems : 1000000,
                    numberPage  : 1,
                    fieldOrder  : 'codigo',
                }
            },
            skip() {
                return true
            },
            result({loading, error}) {
                if(this.accion == 'update'){
                    this.incidencia.concepto_nomina_id = this.concepto_nomina_id
                }
                let formatItemsConceptos = this.conceptosNominas.data;
                /*for(var i = formatItemsConceptos.length - 1; i>=0 ;i--){
                    if(formatItemsConceptos[i].funcion_nombre == "d_IsrAsimilado")
                        formatItemsConceptos.splice(i,1);
                }*/
                formatItemsConceptos.forEach(element => {
                    element["nombre"] = element["codigo"] + " - " + element["descripcion"];
                });
                this.itemsConceptos = formatItemsConceptos;
 
            }
        }
    }
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
    .btn-asociar{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #828282;
        text-transform: none;
        height: 42px !important;
        min-width: 140px !important;
    }
    .titleModalCuentas{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #444975;
    }
    .emptyTableModal{
        padding-top: 50px !important;
        padding-bottom: 50px !important;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center !important;
        color: #c5c5c5;
    }
    .tbl-modal {
        border-collapse: collapse;
        width: 100%;
        margin-top: 15px;
        overflow-x: scroll;
    }

    .tbl-modal .tbl-header {
        background: transparent;
        height: 58px;
        min-width: 400px;
    }
    .modalth {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #828282;
        background: #F6F6F6;
        line-height: 20px;
        padding: 8px;
        text-align: left;
        height: 58px;
    }
    .modaltd {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #1E2144;
        line-height: 15px;
        padding: 8px;
        text-align: left !important;
    }
    .modaltr{
        display: table;
        table-layout: fixed;
        width: 100%;
    }
    .trDivider{
        border-bottom-style: solid; 
        border-bottom-width: 1px;
        border-bottom-color: #F2F2F2;
    }
    #tablaCuenta table {
        border-collapse: collapse;
        width: 100%;
        overflow-x: scroll;
    }
    #tablaCuentaBody {
      display:block;
      max-height:50vh;
      min-width: 90%;
      background-color: #FFFFFF;
    }

    .subtitles-options{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #828282;
    }
</style>
