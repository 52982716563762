import Vue from 'vue';

export default {
    /* find(parametros) {
        return Vue.axios.get("tipo_incidencia/find", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    get(id) {
        return Vue.axios.get("tipo_incidencia/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    }, */
    update(parametros) {
        return Vue.axios.put("tipo_incidencia", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    delete(id) {
        return Vue.axios.delete("tipo_incidencia/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    store(parametros) {
        return Vue.axios.post("tipo_incidencia", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getCodigo(parametros) {
        return Vue.axios.post("tipo_incidencia/codigo", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getTiposIncidencias(parametros) {
        return Vue.axios.get("incidencias_nomina/tipo_incidencia", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateCuenta(parametros) {
        return Vue.axios.post("tipo_incidencia/cuenta-contable", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    deleteCuenta(id) {
        return Vue.axios.delete("tipo_incidencia/cuenta-contable/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    storeCuenta(parametros) {
        return Vue.axios.post("tipo_incidencia/cuenta-contable", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getCuentasContables(parametros) {
        return Vue.axios.get("tipo_incidencia/cuentas-contables", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },

}