import gql from 'graphql-tag';

const tipoIncidenciaTableQuery = gql`
    query tipos_incidencia_nomina($whereConditions: TiposIncidenciaNominaWhereWhereConditions, $numberItems: Int!, $numberPage: Int!, $fieldOrder: String!, $Order: SortOrder!) {
        tipos_incidencia_nomina(where:$whereConditions, first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: $Order}]) {
            data {
                id,
                cliente_id,
                concepto_nomina_id,
                codigo,
                nombre,
                descripcion,
                incluir_vacaciones,
                conceptoNomina{
                    funcion_nombre
                },
                multiple,
                valor_condicional,
                tipo_complemento_imss
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`

const queries = { tipoIncidenciaTableQuery };

export default queries;
